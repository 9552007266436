import React from 'react';
import '../styles/Solutions.css';

const Solutions = () => {
  const solutionsList = [
    {
      id: 1,
      title: "Enterprise AI Systems",
      description: "Custom-built artificial intelligence solutions designed for mission-critical business operations and strategic growth initiatives.",
      icon: (
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#00f0ff" strokeWidth="1.5"/>
          <path d="M8 12L11 15L16 9" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ),
      features: [
        "Natural Language Processing",
        "Predictive Analytics Engines",
        "Computer Vision Implementation",
        "Automated Decision Systems"
      ]
    },
    {
      id: 2,
      title: "Cloud Infrastructure",
      description: "Scalable, secure cloud architecture optimized for performance, reliability, and seamless integration with existing systems.",
      icon: (
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 9C3 7.89543 3.89543 7 5 7H19C20.1046 7 21 7.89543 21 9V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V9Z" stroke="#00f0ff" strokeWidth="1.5"/>
          <path d="M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7" stroke="#00f0ff" strokeWidth="1.5"/>
          <circle cx="12" cy="14" r="3" stroke="#00f0ff" strokeWidth="1.5"/>
        </svg>
      ),
      features: [
        "Multi-Cloud Strategy Planning",
        "Seamless Migration Services",
        "Auto-Scaling Architecture",
        "24/7 Security Monitoring"
      ]
    },
    {
      id: 3,
      title: "Data Intelligence",
      description: "Transform raw data into strategic intelligence through advanced analytics, machine learning, and visualization tools.",
      icon: (
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 3H3V10H10V3Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21 3H14V10H21V3Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M21 14H14V21H21V14Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M10 14H3V21H10V14Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ),
      features: [
        "Real-time Analytics Platforms",
        "Business Intelligence Dashboards",
        "Predictive Modeling Systems",
        "Custom Data Visualization"
      ]
    },
    {
      id: 4,
      title: "Cybersecurity Framework",
      description: "Comprehensive protection strategies designed to safeguard your organization's most critical digital assets and infrastructure.",
      icon: (
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2L3 7V12C3 16.97 7.03 21.5 12 22C16.97 21.5 21 16.97 21 12V7L12 2Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.1716 8.5 10.5 9.17157 10.5 10C10.5 10.8284 11.1716 11.5 12 11.5Z" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 11.5V14.5" stroke="#00f0ff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ),
      features: [
        "Threat Intelligence Systems",
        "Zero-Trust Architecture",
        "Advanced Endpoint Protection",
        "Compliance Management"
      ]
    }
  ];

  return (
    <section id="solutions" className="solutions-section">
      <div className="section-container">
        <div className="section-header">
          <h2 className="section-title">Custom AI <span className="highlight">Solutions</span></h2>
          <p className="section-description">
            Our suite of enterprise-grade technologies and services designed to transform 
            your business through innovation and operational excellence.
          </p>
        </div>
        
        <div className="solutions-grid">
          {solutionsList.map((solution) => (
            <div key={solution.id} className="solution-card">
              <div className="solution-icon">{solution.icon}</div>
              <h3 className="solution-title">{solution.title}</h3>
              <p className="solution-description">{solution.description}</p>
              <ul className="solution-features">
                {solution.features.map((feature, index) => (
                  <li key={index} className="feature-item">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5 12L10 17L19 8" stroke="#00f0ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="solution-action">
                <a href="#contact" className="learn-more">
                  <span>Learn more</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
        
        <div className="section-cta">
          <h3>Ready to transform your business with cutting-edge AI technology?</h3>
          <a href="#consultation" className="cta-button">Schedule a Consultation</a>
        </div>
      </div>
    </section>
  );
};

export default Solutions;