import React, { useRef, useEffect } from 'react';
import '../styles/Hero.css';
import heroVideo from './ai.mp4'; // Import the video file

const Hero = () => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    const videoElement = videoRef.current;
    
    if (videoElement) {
      // Function to ensure proper playback
      const ensurePlayback = () => {
        // Some browsers need this to be explicitly set
        videoElement.muted = true;
        
        // Try to play the video
        videoElement.play().catch(error => {
          console.error("Video play error:", error);
        });
      };
      
      // Function for manual looping (fallback if native loop doesn't work)
      const handleVideoEnd = () => {
        // Reset video to beginning
        videoElement.currentTime = 0;
        ensurePlayback();
      };
      
      // Set up event listeners
      videoElement.addEventListener('loadeddata', ensurePlayback);
      videoElement.addEventListener('ended', handleVideoEnd);
      
      // Clean up on unmount
      return () => {
        videoElement.removeEventListener('loadeddata', ensurePlayback);
        videoElement.removeEventListener('ended', handleVideoEnd);
      };
    }
  }, []);

  return (
    <section id="home" className="hero-section">
      <div className="hero-background">
        <video
          ref={videoRef}
          className="hero-video"
          key="hero-video"
          src={heroVideo}
          muted
          playsInline
          autoPlay
          loop
          preload="auto"
        >
        </video>
        <div className="background-overlay"></div>
      </div>
      
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-tagline">Custom AI Development</div>
          <h1 className="hero-title">
            <span className="title-line">We Build Custom</span>
            <span className="title-line">AI Solutions For</span>
            <span className="title-line">Your Business</span>
          </h1>
          
          <p className="hero-subtitle">
            From concept to deployment, we develop tailor-made AI solutions 
            that solve your specific business challenges. Our expert team creates 
            powerful, scalable technology that delivers measurable results.
          </p>
          
          <div className="hero-actions">
            <a href="#consultation" className="action-button primary">
              <span>Schedule Consultation</span>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
            <a href="#clients" className="action-button secondary">View Case Studies</a>
          </div>
        </div>
        
        <div className="hero-stats">
          <div className="stat-item">
            <div className="stat-value">42%</div>
            <div className="stat-label">Average ROI Increase</div>
          </div>
          <div className="stat-divider"></div>
          <div className="stat-item">
            <div className="stat-value">300+</div>
            <div className="stat-label">Enterprise Clients</div>
          </div>
          <div className="stat-divider"></div>
          <div className="stat-item">
            <div className="stat-value">5+</div>
            <div className="stat-label">Years of AI Expertise</div>
          </div>
        </div>
      </div>
      
      <a href="#solutions" className="scroll-indicator">
        <span>Explore Our Solutions</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </a>
    </section>
  );
};

export default Hero;