import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import logo from './logo.jpeg';
import { auth } from '../firebase'; // Import auth from your firebase.js file
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [user, setUser] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Set up auth state listener
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    
    // Clean up listeners
    return () => {
      window.removeEventListener('scroll', handleScroll);
      unsubscribe();
    };
  }, []);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
    setError('');
  };
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setShowLoginForm(false);
      setEmail('');
      setPassword('');
    } catch (error) {
      setError('Invalid email or password');
      console.error('Login error:', error.message);
    }
  };
  
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={logo} alt="Delta Technologies Logo" className="logo-image" />
          <div className="logo-text">
            <span className="company-name">Delta</span>
            <span className="company-domain">Technologies.Ai</span>
          </div>
        </div>
        
        <div className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
          <ul className="menu-items">
            <li><a href="#home">Home</a></li>
            <li><a href="#solutions">Business Solutions</a></li>
            <li><a href="#clients">Success Stories</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#contact">Contact</a></li>
            <li className="cta-item">
              <a href="#consultation" className="cta-button">Book a Consultation</a>
            </li>
            <li>
              {user ? (
                <a href="#" onClick={handleLogout}>Sign Out</a>
              ) : (
                <a href="#" onClick={toggleLoginForm}>Sign In</a>
              )}
            </li>
          </ul>
        </div>
        
        <div className="navbar-actions">
          {user && (
            <div className="user-menu">
              <span className="user-email">{user.email}</span>
            </div>
          )}
          <div className={`mobile-toggle ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      
      {/* Login Form Modal */}
      {showLoginForm && !user && (
        <div className="login-modal">
          <div className="login-form-container">
            <button className="close-button" onClick={toggleLoginForm}>×</button>
            <h2>Sign In to Delta Technologies</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="login-submit-button">Sign In</button>
            </form>
            <p className="login-footer">
              <a href="#forgot-password">Forgot Password?</a>
            </p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;