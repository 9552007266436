// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIFpFCCeYTnROoM02NboLW5Vn_5Kyz8_8",
  authDomain: "deltatechnologies-901e4.firebaseapp.com",
  projectId: "deltatechnologies-901e4",
  storageBucket: "deltatechnologies-901e4.firebasestorage.app",
  messagingSenderId: "88492148848",
  appId: "1:88492148848:web:37baa6889a96723e8e4cb5",
  measurementId: "G-68P10YL04J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);