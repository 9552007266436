import React from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import Solutions from './Solutions';
import Expertise from './Expertise';
import Clients from './Clients';
import Consultation from './Consultation';
import Contact from './Contact';
import Footer from './Footer';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <Navbar />
      <Hero />
      <div className="section-divider">
        <div className="divider-line"></div>
      </div>
      <Solutions />
      <div className="section-divider">
        <div className="divider-line"></div>
      </div>
      <Expertise />
      <div className="section-divider">
        <div className="divider-line"></div>
      </div>
      <Clients />
      <div className="section-divider">
        <div className="divider-line"></div>
      </div>
      <Consultation />
      <div className="section-divider">
        <div className="divider-line"></div>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default HomePage;