import React, { useState } from 'react';
import '../styles/Expertise.css';

const Expertise = () => {
  const [activeTab, setActiveTab] = useState('ai');

  const expertise = {
    ai: {
      title: "Artificial Intelligence",
      description: "Our AI expertise spans across industries, delivering solutions that transform business operations and drive innovation.",
      image: "ai-image.jpg", // Replace with actual image path
      capabilities: [
        {
          title: "Machine Learning",
          description: "Custom algorithms that learn from data patterns to make predictions and automate decision-making processes."
        },
        {
          title: "Natural Language Processing",
          description: "Advanced systems that understand, interpret, and generate human language for seamless communication interfaces."
        },
        {
          title: "Computer Vision",
          description: "Sophisticated visual recognition technology that enables machines to interpret and process visual information."
        },
        {
          title: "Predictive Analytics",
          description: "Forward-looking analytics that forecast trends, behaviors, and outcomes based on historical data."
        }
      ]
    },
    cloud: {
      title: "Cloud Architecture",
      description: "We design and implement resilient, scalable cloud infrastructure optimized for performance and security.",
      image: "cloud-image.jpg", // Replace with actual image path
      capabilities: [
        {
          title: "Multi-Cloud Strategy",
          description: "Unified management across multiple cloud providers to optimize costs and enhance resilience."
        },
        {
          title: "Cloud Migration",
          description: "Seamless transition of legacy systems to modern cloud environments with minimal disruption."
        },
        {
          title: "Serverless Computing",
          description: "Event-driven architecture that scales automatically and eliminates infrastructure management overhead."
        },
        {
          title: "DevOps Automation",
          description: "Continuous integration and deployment pipelines that streamline software delivery processes."
        }
      ]
    },
    data: {
      title: "Data Engineering",
      description: "Our data engineering capabilities enable organizations to harness the full potential of their data assets.",
      image: "data-image.jpg", // Replace with actual image path
      capabilities: [
        {
          title: "Data Pipelines",
          description: "Robust ETL processes that transform raw data into structured, analysis-ready formats."
        },
        {
          title: "Real-time Analytics",
          description: "Streaming analytics platforms that process and analyze data as it's generated for immediate insights."
        },
        {
          title: "Data Warehousing",
          description: "Enterprise-scale storage solutions optimized for analytical queries and business intelligence."
        },
        {
          title: "Big Data Processing",
          description: "Distributed computing frameworks that handle massive datasets with speed and reliability."
        }
      ]
    },
    security: {
      title: "Cybersecurity",
      description: "We implement comprehensive security measures to protect your most valuable digital assets and infrastructure.",
      image: "security-image.jpg", // Replace with actual image path
      capabilities: [
        {
          title: "Threat Intelligence",
          description: "Proactive identification and monitoring of emerging security threats and vulnerabilities."
        },
        {
          title: "Zero-Trust Architecture",
          description: "Security model that requires strict identity verification for every person and system accessing resources."
        },
        {
          title: "Security Operations",
          description: "24/7 monitoring and incident response to detect and mitigate security breaches in real-time."
        },
        {
          title: "Compliance Management",
          description: "Frameworks and tools to ensure adherence to regulatory requirements and industry standards."
        }
      ]
    }
  };

  return (
    <section id="expertise" className="expertise-section">
      <div className="section-container">
        <div className="section-header">
          <h2 className="section-title">Technical <span className="highlight">Expertise</span></h2>
          <p className="section-description">
            Our deep specialization in cutting-edge technologies enables us to deliver transformative 
            solutions that address the most complex business challenges.
          </p>
        </div>
        
        <div className="expertise-tabs">
          <div className="tabs-navigation">
            <button 
              className={`tab-button ${activeTab === 'ai' ? 'active' : ''}`}
              onClick={() => setActiveTab('ai')}
            >
              <span className="tab-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="currentColor"/>
                  <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" fill="currentColor"/>
                </svg>
              </span>
              <span className="tab-label">Artificial Intelligence</span>
            </button>
            <button 
              className={`tab-button ${activeTab === 'cloud' ? 'active' : ''}`}
              onClick={() => setActiveTab('cloud')}
            >
              <span className="tab-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04Z" fill="currentColor"/>
                </svg>
              </span>
              <span className="tab-label">Cloud Architecture</span>
            </button>
            <button 
              className={`tab-button ${activeTab === 'data' ? 'active' : ''}`}
              onClick={() => setActiveTab('data')}
            >
              <span className="tab-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7 14V10H10V7H14V10H17V14H14V17H10V14H7Z" fill="currentColor"/>
                </svg>
              </span>
              <span className="tab-label">Data Engineering</span>
            </button>
            <button 
              className={`tab-button ${activeTab === 'security' ? 'active' : ''}`}
              onClick={() => setActiveTab('security')}
            >
              <span className="tab-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z" fill="currentColor"/>
                </svg>
              </span>
              <span className="tab-label">Cybersecurity</span>
            </button>
          </div>
          
          <div className="tabs-content">
            <div className="expertise-content">
              <div className="expertise-info">
                <h3 className="expertise-title">{expertise[activeTab].title}</h3>
                <p className="expertise-description">{expertise[activeTab].description}</p>
                
                <div className="expertise-capabilities">
                  {expertise[activeTab].capabilities.map((capability, index) => (
                    <div key={index} className="capability-item">
                      <h4 className="capability-title">{capability.title}</h4>
                      <p className="capability-description">{capability.description}</p>
                    </div>
                  ))}
                </div>
                
                <a href="#contact" className="expertise-cta">
                  <span>Discuss your project</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </a>
              </div>
              
              <div className="expertise-visual">
                <div className="visual-placeholder">
                  <div className="placeholder-content">
                    {activeTab === 'ai' && (
                      <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#00f0ff" opacity="0.8"/>
                        <path d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z" fill="#00f0ff" opacity="0.8"/>
                      </svg>
                    )}
                    {activeTab === 'cloud' && (
                      <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04Z" fill="#00f0ff" opacity="0.8"/>
                      </svg>
                    )}
                    {activeTab === 'data' && (
                      <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7 14V10H10V7H14V10H17V14H14V17H10V14H7Z" fill="#00f0ff" opacity="0.8"/>
                      </svg>
                    )}
                    {activeTab === 'security' && (
                      <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z" fill="#00f0ff" opacity="0.8"/>
                      </svg>
                    )}
                    <span className="placeholder-text">{expertise[activeTab].title} Visualization</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expertise;