import React, { useState } from 'react';
import '../styles/Consultation.css';
import { database } from '../firebase';
import { ref, push, set } from 'firebase/database';

const Consultation = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    service: 'ai-solutions',
    message: '',
    preferredDate: '',
    preferredTime: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormError('');
    
    try {
      // Create a new reference for the consultation request
      const consultationRef = ref(database, 'consultations');
      const newConsultationRef = push(consultationRef);
      
      // Add timestamp
      const consultationData = {
        ...formData,
        timestamp: Date.now(),
        status: 'pending'
      };
      
      // Save to Firebase
      await set(newConsultationRef, consultationData);
      
      // Show success message
      setFormSubmitted(true);
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        company: '',
        phone: '',
        service: 'ai-solutions',
        message: '',
        preferredDate: '',
        preferredTime: ''
      });
    } catch (error) {
      console.error('Error submitting consultation request:', error);
      setFormError('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const services = [
    { value: 'ai-solutions', label: 'AI & Machine Learning Solutions' },
    { value: 'data-analytics', label: 'Data Analytics & Business Intelligence' },
    { value: 'process-automation', label: 'Process Automation' },
    { value: 'cloud-integration', label: 'Cloud Integration' },
    { value: 'custom-software', label: 'Custom Software Development' },
    { value: 'other', label: 'Other Services' }
  ];

  return (
    <section id="consultation" className="consultation-section">
      <div className="background-overlay"></div>
      
      <div className="consultation-container">
        <div className="consultation-heading">
          <h2 className="section-title">Book Your Free Consultation</h2>
          <p className="section-subtitle">
            Discuss your business challenges with our experts and discover how our AI-powered solutions can transform your operations.
          </p>
        </div>
        
        <div className="consultation-content">
          <div className="consultation-info">
            <div className="info-card">
              <div className="info-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
              </div>
              <h3>30-Minute Strategy Session</h3>
              <p>
                Schedule a focused call with our experts to discuss your specific business challenges and explore potential solutions.
              </p>
            </div>
            
            <div className="info-card">
              <div className="info-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                  <path d="M9 12h6"></path>
                  <path d="M9 16h6"></path>
                </svg>
              </div>
              <h3>Custom Solution Blueprint</h3>
              <p>
                After our call, receive a tailored action plan with recommendations specifically designed for your business needs.
              </p>
            </div>
            
            <div className="info-card">
              <div className="info-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                </svg>
              </div>
              <h3>No Obligation</h3>
              <p>
                Our consultation is completely free with no strings attached. We're committed to providing value even if you decide not to proceed.
              </p>
            </div>
          </div>
          
          <div className="consultation-form-container">
            {formSubmitted ? (
              <div className="success-message">
                <div className="success-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <h3>Consultation Request Sent!</h3>
                <p>
                  Thank you for reaching out. One of our experts will contact you within 24 hours to schedule your consultation.
                </p>
                <button 
                  className="back-button"
                  onClick={() => setFormSubmitted(false)}
                >
                  Schedule Another Consultation
                </button>
              </div>
            ) : (
              <form className="consultation-form" onSubmit={handleSubmit}>
                {formError && <div className="form-error">{formError}</div>}
                
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">Full Name *</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Enter your full name"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="email">Email Address *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder="Enter your email address"
                    />
                  </div>
                </div>
                
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="company">Company Name</label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      placeholder="Enter your company name"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                    />
                  </div>
                </div>
                
                <div className="form-group">
                  <label htmlFor="service">Service of Interest *</label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    {services.map(service => (
                      <option key={service.value} value={service.value}>
                        {service.label}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="form-group">
                  <label htmlFor="message">Tell us about your project/needs *</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows="4"
                    placeholder="Briefly describe your business challenges and what you're looking to achieve"
                  ></textarea>
                </div>
                
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="preferredDate">Preferred Date</label>
                    <input
                      type="date"
                      id="preferredDate"
                      name="preferredDate"
                      value={formData.preferredDate}
                      onChange={handleChange}
                      min={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="preferredTime">Preferred Time</label>
                    <select
                      id="preferredTime"
                      name="preferredTime"
                      value={formData.preferredTime}
                      onChange={handleChange}
                    >
                      <option value="">Select a time slot</option>
                      <option value="morning">Morning (9AM - 12PM)</option>
                      <option value="afternoon">Afternoon (1PM - 5PM)</option>
                      <option value="evening">Evening (6PM - 8PM)</option>
                    </select>
                  </div>
                </div>
                
                <div className="form-footer">
                  <p className="form-disclaimer">
                    By submitting this form, you agree to our privacy policy and terms of service.
                  </p>
                  <button 
                    type="submit" 
                    className="submit-button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Book Your Consultation'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Consultation;