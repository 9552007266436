import React from 'react';
import '../styles/Clients.css';

const Clients = () => {
  // Replace with actual client logos and case studies
  const testimonials = [
    {
      id: 1,
      quote: "Delta Technologies delivered an AI solution that increased our operational efficiency by 42% and reduced costs by $2.3M annually.",
      author: "Sarah Chen",
      title: "CTO",
      company: "Global Financial Services"
    },
    {
      id: 2,
      quote: "Their cloud architecture expertise transformed our legacy infrastructure into a scalable, resilient system that handles 10x our previous capacity.",
      author: "Michael Rodriguez",
      title: "VP of Engineering",
      company: "HealthTech Innovations"
    },
    {
      id: 3,
      quote: "The predictive analytics platform they built has fundamentally changed how we make strategic decisions, identifying $5M in new revenue opportunities.",
      author: "Emily Takahashi",
      title: "Director of Data Science",
      company: "Retail Enterprises"
    }
  ];
  
  const caseStudies = [
    {
      id: 1,
      title: "Financial Services AI Transformation",
      industry: "Banking & Finance",
      results: [
        "42% increase in operational efficiency",
        "$2.3M annual cost reduction",
        "99.8% system reliability"
      ],
      image: "case-study-1.jpg" // Replace with actual image
    },
    {
      id: 2,
      title: "Healthcare Cloud Migration",
      industry: "Healthcare",
      results: [
        "10x increase in system capacity",
        "99.99% uptime achieved",
        "68% faster deployment cycles"
      ],
      image: "case-study-2.jpg" // Replace with actual image
    },
    {
      id: 3,
      title: "Retail Predictive Analytics",
      industry: "Retail",
      results: [
        "$5M in new revenue opportunities",
        "87% prediction accuracy",
        "3x faster market response"
      ],
      image: "case-study-3.jpg" // Replace with actual image
    }
  ];

  return (
    <section id="clients" className="clients-section">
      <div className="section-container">
        <div className="section-header">
          <h2 className="section-title">Trusted by <span className="highlight">Industry Leaders</span></h2>
          <p className="section-description">
            We've partnered with forward-thinking enterprises across industries to deliver 
            transformative technology solutions with measurable business impact.
          </p>
        </div>
        
        <div className="client-logos">
          {/* Replace with actual client logos */}
          <div className="logo-placeholder">
            <span>Enterprise 1</span>
          </div>
          <div className="logo-placeholder">
            <span>Finance Co.</span>
          </div>
          <div className="logo-placeholder">
            <span>Health Tech</span>
          </div>
          <div className="logo-placeholder">
            <span>Retail Group</span>
          </div>
          <div className="logo-placeholder">
            <span>Tech Inc.</span>
          </div>
          <div className="logo-placeholder">
            <span>Manufacturing</span>
          </div>
        </div>
        
        <div className="testimonials-container">
          <div className="testimonials-wrapper">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-card">
                <div className="quote-mark">
                  <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 11H6C5.46957 11 4.96086 10.7893 4.58579 10.4142C4.21071 10.0391 4 9.53043 4 9V7C4 6.46957 4.21071 5.96086 4.58579 5.58579C4.96086 5.21071 5.46957 5 6 5H8C8.53043 5 9.03914 5.21071 9.41421 5.58579C9.78929 5.96086 10 6.46957 10 7V15C10 16.0609 9.57857 17.0783 8.82843 17.8284C8.07828 18.5786 7.06087 19 6 19H5M20 11H16C15.4696 11 14.9609 10.7893 14.5858 10.4142C14.2107 10.0391 14 9.53043 14 9V7C14 6.46957 14.2107 5.96086 14.5858 5.58579C14.9609 5.21071 15.4696 5 16 5H18C18.5304 5 19.0391 5.21071 19.4142 5.58579C19.7893 5.96086 20 6.46957 20 7V15C20 16.0609 19.5786 17.0783 18.8284 17.8284C18.0783 18.5786 17.0609 19 16 19H15" stroke="#00FF00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <p className="testimonial-text">{testimonial.quote}</p>
                <div className="testimonial-author">
                  <div className="author-avatar">
                    {/* Replace with actual avatar */}
                    <div className="avatar-placeholder">
                      {testimonial.author.charAt(0)}
                    </div>
                  </div>
                  <div className="author-info">
                    <h4 className="author-name">{testimonial.author}</h4>
                    <p className="author-title">{testimonial.title}</p>
                    <p className="author-company">{testimonial.company}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="case-studies-header">
          <h3>Case Studies</h3>
          <p>Real-world examples of how our solutions drive business transformation</p>
        </div>
        
        <div className="case-studies-grid">
          {caseStudies.map((caseStudy) => (
            <div key={caseStudy.id} className="case-study-card">
              <div className="case-study-visual">
                {/* Replace with actual image */}
                <div className="case-study-placeholder">
                  <span>{caseStudy.industry}</span>
                </div>
              </div>
              <div className="case-study-content">
                <span className="case-study-industry">{caseStudy.industry}</span>
                <h4 className="case-study-title">{caseStudy.title}</h4>
                <div className="case-study-results">
                  <h5>Results</h5>
                  <ul className="results-list">
                    {caseStudy.results.map((result, index) => (
                      <li key={index} className="result-item">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#00FF00" strokeWidth="2"/>
                          <path d="M8 12L11 15L16 9" stroke="#00FF00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>{result}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <a href="#contact" className="case-study-link">
                  <span>View full case study</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;