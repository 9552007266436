// src/components/Footer.js
import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src="./logo.jpeg" alt="Delta Technologies Logo" className="footer-logo-img" />
            <h3 className="footer-logo-text">deltatechnologies.ai</h3>
          </div>
          
          <div className="footer-links">
            <div className="footer-links-column">
              <h4>Company</h4>
              <ul>
                <li><a href="#about">About Us</a></li>
                <li><a href="#careers">Careers</a></li>
                <li><a href="#team">Our Team</a></li>
                <li><a href="#blog">Blog</a></li>
              </ul>
            </div>
            
            <div className="footer-links-column">
              <h4>Services</h4>
              <ul>
                <li><a href="#ai">AI Solutions</a></li>
                <li><a href="#cloud">Cloud Services</a></li>
                <li><a href="#data">Data Analytics</a></li>
                <li><a href="#security">Cybersecurity</a></li>
              </ul>
            </div>
            
            <div className="footer-links-column">
              <h4>Support</h4>
              <ul>
                <li><a href="#contact">Contact Us</a></li>
                <li><a href="#faq">FAQ</a></li>
                <li><a href="#docs">Documentation</a></li>
                <li><a href="#terms">Terms of Service</a></li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom">
          <div className="social-icons">
            <a href="#" className="social-icon">LinkedIn</a>
            <a href="#" className="social-icon">Twitter</a>
            <a href="#" className="social-icon">GitHub</a>
          </div>
          
          <div className="copyright">
            <p>&copy; {new Date().getFullYear()} Delta Technologies. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;